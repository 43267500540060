import { createSlice } from '@reduxjs/toolkit';

export const caasDataSlice = createSlice({
  name: 'caasData',
  initialState: {}
});

export const getCaasData = (state, data) => state.caasData[data];

export default caasDataSlice.reducer;
