import window from 'global';
import document from 'global/document';
import React from 'react';
import { hydrate } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import App from './App';
import ClientComponentLoader from './app/common/clientComponentLoader';
import {
  AWS_ENV_VARS,
  ENVIRONMENT,
  LANGUAGE,
  PRELOADED_STATE_KEY,
  ROUTER_BASENAME
} from './app/common/constants';
import { EnvProvider } from './app/common/envContext';
import logger from './app/common/logger';
import { getEnvironmentConstant } from './app/common/utilities';
import UniversalAssistant from './app/components/UniversalAssistant';
import { generateStore } from './app/store/store';

let preloadedState = {};
// Grab the state from a global variable injected into the server-generated HTML
preloadedState = window[PRELOADED_STATE_KEY];

const defaultEnvData = {};
Object.keys(AWS_ENV_VARS).forEach((key) => {
  defaultEnvData[key] = AWS_ENV_VARS[key].default;
});

const env = preloadedState?.envData || defaultEnvData;
const currentPath = preloadedState?.envData?.pageType || '';

env.COMPONENT_LOADER = ClientComponentLoader;

// eslint-disable-next-line camelcase,no-undef
__webpack_public_path__ = `${getEnvironmentConstant({
  key: ROUTER_BASENAME,
  env: env[ENVIRONMENT]
})}/`;

const store = generateStore(preloadedState);

/**
 * This is used for UniversalAssistant
 * product page that has productListCode
 */
let plc;

if (env.pageType && env.pageType === 'product') {
  if (
    window[PRELOADED_STATE_KEY] &&
    window[PRELOADED_STATE_KEY].productData &&
    window[PRELOADED_STATE_KEY].productData.productListCode
  ) {
    plc = window[PRELOADED_STATE_KEY].productData.productListCode;
  }
}

const environment = env[ENVIRONMENT];
const language = env[LANGUAGE];
const { loadAA } = env;

logger.info('Load AA?', loadAA || 'false or missing parameter; check configs');

const hydrateApplication = () => {
  hydrate(
    <HelmetProvider>
      <Provider store={store}>
        <EnvProvider value={env}>
          <App currentPath={currentPath} />
          {loadAA ? (
            <div
              id="uhm-iframe-container"
              data-wat-link-section="uhm"
              data-wat-val="question-mark"
              data-wat-uhm-initiate="question-mark"
              className="uhm-lang-en_US minimized"
            >
              <UniversalAssistant
                environment={environment}
                language={language}
                pageTitle={document.title}
                product={plc}
              />
            </div>
          ) : null}
        </EnvProvider>
      </Provider>
    </HelmetProvider>,
    document.getElementById('etx-root')
  );
};

// check if currentPath is not empty
if (currentPath) {
  ClientComponentLoader[currentPath].load().then(() => {
    hydrateApplication();
  });
} else {
  logger.error(
    `Cannot hydrateApplication - no current path found: ${document?.location?.href}`
  );
}
