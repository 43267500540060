/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import window from 'global/window';

export const errorSlice = createSlice({
  name: 'errorData',
  initialState: {
    hasError: false,
    errorType: null,
    metaData: null
  },
  reducers: {
    setErrorState: (state, action) => {
      window.scrollTo(0, 0);
      state.hasError = true;
      state.errorType = action?.payload?.type;
      state.metaData = action?.payload?.meta;
    },
    clearErrorState: (state) => {
      state.hasError = false;
      state.errorType = null;
      state.metaData = null;
    }
  }
});

export const { setErrorState, clearErrorState } = errorSlice.actions;

// export const getErrorState = (state) => state.errorData;

export default errorSlice.reducer;
