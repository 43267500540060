import { AuthClient } from '@adsk/identity-web-sdk';
import { adpDotcomLoggerUtil } from '@adsk/ha-adp-dotcom-logger-util';
import darkTheme from '@digital-hig/theme-data/lib/build/json/darkTheme/nestedRoles.json';
import { detect } from 'detect-browser';
import window from 'global/window';
import { PropTypes } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { coerce, lt, valid } from 'semver';
import { createGlobalStyle } from 'styled-components';
import {
  AWS_ENV_VARS,
  ENVIRONMENT,
  ERROR_TYPE_BROWSER,
  LANGUAGE,
  PKCE_CLIENT_ID,
  PKCE_ENV,
  ROUTER_BASENAME
} from './app/common/constants';
import EnvContext from './app/common/envContext';
import handleUnauthRedirect from './app/common/handleUnauthRedirect';
import logger from './app/common/logger';
import { LoginClickHandler, setLoginRedirects } from './app/common/login';
import authLib from './app/common/pkce';
import { getEnvironmentConstant } from './app/common/utilities';
// import UniversalAssistant from './app/components/UniversalAssistant';
import { setErrorState } from './app/store/errorSlice';
import fetchUserData from './app/store/userSlice';
import { defaultLanguageCountry } from './app/utils/languageSupport';

export const checkElement = async (selector, singleElement = true) => {
  while (document.querySelector(selector) === null) {
    // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
    await new Promise((resolve) => requestAnimationFrame(resolve));
  }
  if (singleElement) {
    return document.querySelector(selector);
  }

  return document.querySelectorAll(selector);
};

const enableAssistantButton = (assistantButton) => {
  if (assistantButton) {
    document.body.addEventListener('aa-init-complete', () => {
      logger.info(`<---aa-init-complete--->`);
      const isLoading = assistantButton.matches(
        '.cmp-button.assistant-loading'
      );
      if (isLoading) {
        assistantButton.classList.replace(
          'assistant-loading',
          'assistant-ready'
        );
        assistantButton.classList.remove('Mui-disabled');
      }
    });
  }
};

/**
 * Function to load AA inline or if the iFrame hasn't loaded, let user
 * redirect to /support/contact-support. The AEM XF has this URL hard-coded
 * and we are just interrupting it until such time the AEM XF may be updated.
 * Includes separate ADP tracking for each.
 */
const loadAA = (tracker, locale) => {
  window.launchAA = (event) => {
    if (!document.querySelector('.uhm-iframe')) {
      logger.error('launchAA', 'Failed to instantiate AA, iFrame is empty');
      tracker.track('contact_support_click', { ev: 'contact_support_button' });
      return true;
    }
    event.preventDefault();
    // if it's already open, don't continually reload it
    if (
      !document
        .querySelector('#uhm-iframe-container')
        .classList.contains('opened')
    ) {
      if (
        window.adskUniversalHelpOptionsGlobal &&
        window.adskUniversalHelpOptionsGlobal?.adskUpdateUHMOptions
      ) {
        if (locale === 'en-US') {
          window.adskUniversalHelpOptionsGlobal.adskUpdateUHMOptions({
            loadUserFlow: 'preSalesAi'
          });
        } else {
          window.adskUniversalHelpOptionsGlobal.adskUpdateUHMOptions({
            deepLinkToValue: 'start',
            contextualLinkOrigin: 'open-ua'
          });
        }
        tracker.track('uhm_icon_click', { ev: 'contact_support_button' });
      }
    }
    return false;
  };

  // interrupt the Contact support button; can't use .assistant-onclick because it is not present
  // for non-English
  checkElement('#contact-support .xfpage-content-container a').then(
    (selector) => {
      selector.addEventListener('click', window.launchAA, false);
      enableAssistantButton(selector);
    }
  );

  /**
   * There is no easy class to target Cart's parent <li>, and it's location in the <ul> changes depending
   * on screen width and existence of messaging, so it's simplest to target it via JS.
   *
   * Reference here:
   * https://git.autodesk.com/WebDelivery/mfe-universal-header/blob/master/source/app/components/container/Index.jsx
   */
  checkElement('.uh-cart-container').then((selector) => {
    // eslint-disable-next-line no-param-reassign
    selector.parentNode.style.display = 'none';
  });
};

const getAkamaiCookie = (cName) => {
  const ca = document?.cookie?.split(';');
  let cookieValue = '';

  ca.forEach((cookie) => {
    const c = cookie.replace(/^\s+|\s+$/gm, '');
    if (c.indexOf(cName) !== -1)
      cookieValue = c.substring(cName.length + 1, c.length);
  });

  return cookieValue;
};

function App({ currentPath }) {
  const dispatch = useDispatch();
  const envContext = useContext(EnvContext);
  const componentLoader = envContext.COMPONENT_LOADER;
  const env = envContext[ENVIRONMENT];
  const defaultEnvData = {};
  Object.keys(AWS_ENV_VARS).forEach((key) => {
    defaultEnvData[key] = AWS_ENV_VARS[key].default;
  });

  const language = envContext[LANGUAGE] || defaultLanguageCountry;
  const environment = envContext[ENVIRONMENT] || 'prd';
  const adpConfig = envContext.ADP_CONFIG;
  const server = {
    adpConfig
  };
  const tracker = adpDotcomLoggerUtil(server);

  useEffect(() => {
    const getLanguageSelector = () => {
      let languageSelector = {};
      if (envContext && envContext.languageSelector) {
        languageSelector = envContext.languageSelector;
      }

      const country = getAkamaiCookie('cdn-user-cc');
      languageSelector.country = country || 'US'; // do we want to default to language of site?
      return languageSelector;
    };

    const components = {
      languageSelector: getLanguageSelector(),
      meMenu: {
        onSignIn: () => {
          LoginClickHandler();
        },
        onSignOut: () => {
          authLib.authClient
            .logout({
              post_logout_redirect_uri: window.location.href
            })
            .then(() => {
              handleUnauthRedirect({ env });
            });
        }
      }
    };

    const uhEventListener = (response) => {
      if (response) {
        logger.debug('UH Response', response.event);
      }
    };

    const headerConfig = {
      property: 'dotcom',
      propertyPageWidth: '1248px',
      language,
      environment,
      components,
      eventListener: uhEventListener
    };

    if (
      envContext[PKCE_CLIENT_ID] &&
      envContext[PKCE_CLIENT_ID] !== undefined
    ) {
      if (window?.crypto) {
        authLib.init(
          new AuthClient({
            client_id: envContext[PKCE_CLIENT_ID],
            redirect_uri: `${window?.location?.origin}${getEnvironmentConstant({
              key: ROUTER_BASENAME,
              env
            })}/auth`,
            useRefreshTokens: true,
            env: getEnvironmentConstant({ key: PKCE_ENV, env }),
            scope: 'data:read openid user-profile:read'
          })
        );
      }
    } else {
      // TBD: do we care? When will this ever be empty??
      logger.error('APP: PKCE_CLIENT_ID not set');
    }

    if (
      window.adsk &&
      window.adsk.components &&
      window.adsk.components.universalHeader
    ) {
      window.adsk.components.universalHeader.render(
        'etx-uh-container',
        headerConfig
      );
    }

    const validateSignIn = () => {
      // We don't need to do anything here
      // "Sign in" Me Menu not appearing without this
      dispatch(fetchUserData(env)).then((userDataResponse) => {
        if (userDataResponse.type === 'userData/fulfilled') {
          if (
            !userDataResponse.payload.userid ||
            userDataResponse.payload.userid?.length < 4
          ) {
            logger.error(
              `INVALID UID - ValidateSignIn: |${userDataResponse.payload.userid}|`
            );
          }
        }
      });
    };

    validateSignIn();
    let authValidationInterval;
    if (authValidationInterval) {
      clearInterval(authValidationInterval);
    }
    authValidationInterval = setInterval(validateSignIn, 3000000);

    setLoginRedirects();

    loadAA(tracker, language);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Detect browser state
    const browser = detect();
    /*
    const unsupported = {
      os: ['BlackBerry OS', 'Windows 3.11'],
      browser: [{ browser: 'ie', lessThan: 'any' }, { browser: 'edge', lessThan: '84' }, { browser: 'chrome', lessThan: '83' }],
      combo: [{ os: 'Mac OS', browser: 'edge', lessThan: 'any' }, { os: 'Windows XP', browser: 'firefox', lessThan: '80.0' }],
    };
    */

    const unsupported = {
      browser: [{ browser: 'ie', lessThan: 'any' }]
    };

    // // handle the case where we don't detect the browser
    if (browser) {
      const isBrowserUnsupported = unsupported.browser
        ? unsupported.browser.filter((b) => {
            if (b.browser === browser.name) {
              if (
                b.lessThan === 'any' ||
                lt(browser.version, valid(coerce(b.lessThan)))
              ) {
                return true;
              }
            }

            return false;
          })
        : [];
      const isComboUnsupported = unsupported.combo
        ? unsupported.combo.filter((b) => {
            if (b.os === browser.os) {
              if (b.browser === browser.name) {
                if (
                  b.lessThan === 'any' ||
                  lt(browser.version, valid(coerce(b.lessThan)))
                ) {
                  return true;
                }
              }
            }
            return false;
          })
        : [];
      if (isBrowserUnsupported.length) {
        dispatch(
          setErrorState({
            type: ERROR_TYPE_BROWSER,
            metaData: { browser, type: 'browser' }
          })
        );
      } else if (isComboUnsupported.length) {
        dispatch(
          setErrorState({
            type: ERROR_TYPE_BROWSER,
            metaData: { browser, type: 'combo' }
          })
        );
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    import('./app/styles/styles.scss');

    const footerConfig = {
      locale: language,
      environment: 'prd',
      hideMegaFooter: false
    };

    if (
      window.adsk &&
      window.adsk.components &&
      window.adsk.components.universalFooter
    ) {
      window.adsk.components.universalFooter.render(
        'etx-uf-container',
        footerConfig
      );
    }
  }, [language]);

  const CompToRender = componentLoader[currentPath];
  return (
    <>
      <GlobalStyles />
      {['product', 'search', 'caas'].includes(currentPath) ? (
        <CompToRender />
      ) : null}
    </>
  );
}

// Top-level styles can live here. Consider moving to separate file.
const GlobalStyles = createGlobalStyle`
  html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }
  body {
    font-family: ArtifaktElement, Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  .container--header {
    background-color: ${darkTheme.tokens.colors.background.color};
  }
  .container--header, .fixed-header {
    min-height: 57px;
    @media screen and (min-width: 1040px) {
      min-height: 97.5px;
    }
  }
  .etx-root {
    min-height: 300px;
  }
  .page-container-wrapper {
    margin: 0 auto;
    max-width: 100pc;
  }
  .page-container, .page-container-inner {
    margin: 0 5%;
    @media (min-width: 560px) {
      margin: 0 24px;
    }
    @media (min-width: 1040px) {
      margin: 0 5%;
      max-width: 90%;
    }
  }
  .wd-lightbox-overlay {
    display: none;
  }
`;

App.propTypes = {
  currentPath: PropTypes.string
};

App.defaultProps = {
  // Current path is set to blank because methods in router.js
  // pass the App component where currentPath is not yet available. CurrentPath is
  // generated within the render method after this happens
  currentPath: ''
};
export default App;
