import { configureStore } from '@reduxjs/toolkit';
import errorData from './errorSlice';
import envData from './envSlice';
import caasData from './caasDataSlice';
import productData from './productDataSlice';

const reducer = {
  errorData,
  envData,
  productData,
  caasData
};

export default configureStore({
  reducer,
  // devtools works out of the box
  devTools: process.env.NODE_ENV !== 'production'
});

export const generateStore = (preloadedState) =>
  configureStore({
    reducer,
    preloadedState
  });
