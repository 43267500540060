// const window = require('global/window');
// const { resolvedRoles } = require('@digital-hig/theme-mui/theme-data/lib/build/json/digitalHig2020WebDensityTheme/theme.json');

const modExports = {};

modExports.PRELOADED_STATE_KEY = '__PRELOADED_STATE__';
modExports.CURRENT_PATH_KEY = '__CURRENT_PATH__';
modExports.LOGGER_URL = 'LOGGER_URL';
modExports.ENVIRONMENT = 'ENVIRONMENT';
modExports.LANGUAGE = 'LANGUAGE';

modExports.DEV_ENV = 'dev';
modExports.STG_ENV = 'stg';
modExports.PRD_ENV = 'prd';
modExports.MAINTENANCE_MODE = 'MAINTENANCE_MODE';
modExports.HOST = 'HOST';

modExports.LOG_LEVEL = 'LOG_LEVEL';
modExports.LOG_LEVEL_DEBUG = 'debug';
modExports.LOG_LEVEL_INFO = 'info';
modExports.LOG_LEVEL_WARN = 'warn';
modExports.LOG_LEVEL_ERROR = 'error';

modExports.ERROR_TYPE_404 = '404';
modExports.ERROR_TYPE_XHR = 'XHR';
modExports.ERROR_TYPE_IFRAME = 'IFRAME';
modExports.ERROR_TYPE_BROWSER = 'BROWSER';
modExports.ERROR_TYPE_LIST_PERMISSION = 'LIST_PERMISSION';

modExports.VALID_AWS_ENVS = [
  modExports.DEV_ENV,
  modExports.STAGE_ENV,
  modExports.PROD_ENV,
  modExports.PERF_ENV
];
modExports.VALID_LOG_LEVELS = [
  modExports.LOG_LEVEL_DEBUG,
  modExports.LOG_LEVEL_INFO,
  modExports.LOG_LEVEL_WARN,
  modExports.LOG_LEVEL_ERROR
];

modExports.LOCAL_STORAGE_KEY = 'etx.support';
modExports.OXYGEN_REDIRECT_URL_KEY = 'autodesk_oxygen_redir_url';

modExports.PKCE_CLIENT_ID = 'PKCE_CLIENT_ID';
modExports.PKCE_ENV = 'PKCE_ENV';
modExports.PKCE_PROFILE_HOST = 'PKCE_PROFILE_HOST';

modExports.ROUTER_BASENAME = 'ROUTER_BASENAME'; // key not actual route
modExports.VERSION = 'VERSION';
modExports.HTML_MAX_AGE = 'HTML_MAX_AGE';
modExports.RETRY_COUNT = 3;

modExports.COMPONENT_INDEX = 'index';
modExports.COMPONENT_CAAS = 'caas';
modExports.COMPONENT_PRODUCT = 'product';
modExports.COMPONENT_SEARCH = 'search';
modExports.MAINTENANCE_MODE = 'MAINTENANCE_MODE';
// modExports.COMPONENT_NOTFOUND = 'notFound';

/*
 This object is a key/value list of AWS Lambda Environment Variables required for the application.
 The value of each key is an object that contains:
    default (required): default value for this key if it is not present in the env
    validator (optional): a function to validate the value before setting it.  This function should
    return a boolean value.  If false the application will use the default value instead of the value
    that failed validation.
 */
modExports.AWS_ENV_VARS = {
  ENVIRONMENT: {
    default: modExports.DEV_ENV,
    validator: (v) => modExports.VALID_AWS_ENVS.includes(v)
  },
  LOG_LEVEL: {
    default: modExports.LOG_LEVEL_ERROR,
    validator: (v) => modExports.VALID_LOG_LEVELS.includes(v)
  },
  HTML_MAX_AGE: {
    default: '86400',
    validator: (v) => /^[0-9]$/.test(v)
  },
  LOGGER_URL: {}
};

/*
 * This object contains Constants who's values are relative to the environment the application is running in.
 * PKCE_CLIENT_ID is dynamically set in render and App.js based on what is in .env/.env.local.
 * Passed through envContext.
 */
modExports.ENVIRONMENT_CONSTANTS = {
  dev: {
    ENVIRONMENT: modExports.STG_ENV,
    LOG_LEVEL: 'debug',
    HTML_MAX_AGE: '86400',
    PKCE_ENV: 'stg',
    PKCE_PROFILE_HOST: 'https://developer-stg.api.autodesk.com',
    ROUTER_BASENAME: '/support/technical'
  },
  stg: {
    ENVIRONMENT: modExports.STG_ENV,
    LOG_LEVEL: 'debug', // warn
    HTML_MAX_AGE: '86400',
    PKCE_ENV: 'stg',
    PKCE_PROFILE_HOST: 'https://developer-stg.api.autodesk.com',
    ROUTER_BASENAME: '/support/technical'
  },
  prd: {
    ENVIRONMENT: modExports.PRD_ENV,
    LOG_LEVEL: 'error',
    HTML_MAX_AGE: '86400',
    PKCE_ENV: 'prd',
    PKCE_PROFILE_HOST: 'https://developer.api.autodesk.com',
    ROUTER_BASENAME: '/support/technical'
  }
};

/**
 * Configuration for ADP. ApiKey and signWith are set via process.env in render.js.
 */
modExports.ADP_CONFIG = {
  prd: {
    serviceUrl: 'https://akn.analytics.autodesk.com',
    appId: 'knowledge',
    apiKey: '',
    signWith: ''
  },
  stg: {
    serviceUrl: 'https://akn.analytics-staging.autodesk.com',
    appId: 'knowledge',
    apiKey: '',
    signWith: ''
  }
};

// Add ENVIRONMENT_CONSTANTS to AWS_ENV_VARS to allow for overrides
Object.keys(modExports.ENVIRONMENT_CONSTANTS.dev).forEach((key) => {
  modExports.AWS_ENV_VARS[key] = modExports.AWS_ENV_VARS[key] || {};
});

module.exports = modExports;
