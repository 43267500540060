import window from 'global/window';
import { PropTypes } from 'prop-types';
import logger from '../../common/logger';

const setHelpOptions = ({ language, pageTitle, product }) => {
  window.adskUniversalHelpOptions = {
    language,
    pageTitle,
    product
  };
};

const appendScript = (environment) => {
  const script = document.createElement('script');
  script.src = `https://app.digital-help-${environment}.autodesk.com/universal-help/current/uhm-${environment}.min.js`;
  script.type = 'text/javascript';
  script.async = true;
  document.body.appendChild(script);
};

const UniversalAssistant = (props) => {
  logger.debug('UniversalAssistant props', props);

  const { environment, language, pageTitle, product } = props;

  setHelpOptions({
    environment,
    language: language.replace('-', '_'),
    pageTitle,
    product
  });

  appendScript(environment);

  // setTimeout(() => {
  //   appendScript(environment);
  // }, 300);

  return null;
};

UniversalAssistant.propTypes = {
  /**
   * environment (stg or prd environment) prop
   */
  environment: PropTypes.string,
  /**
   * language prop
   */
  language: PropTypes.string,
  /**
   * pageTitle prop
   */
  pageTitle: PropTypes.string,
  /**
   * product (this is productListCode or plc) prop
   */
  product: PropTypes.string
};

UniversalAssistant.defaultProps = {
  environment: 'prd',
  language: '',
  pageTitle: '',
  product: 'global'
};

export default UniversalAssistant;

// Pulled from https://git.autodesk.com/Account-Portal/kepler/commit/aa1a3897266e728d2dcfe9b5e7e99afd8a68215a
// do we need to have a profileSubscriber callback??

// const profileSubscriber = callback => {
//   window.cfp.providers.data.subscribe(DataKeys.USER_PROFILE, async (profile) => {
//     if (!profile) return null;

//     callback(profile);
//   });
// }
