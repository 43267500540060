import window from 'global';
import { getEnvironmentConstant, stripTrailingSlash } from './utilities';
import { ROUTER_BASENAME } from './constants';

// we don't technically need this...
export default ({ env }) => {
  const curPath = stripTrailingSlash(window.location.href);
  switch (true) {
    case curPath.includes(
      `${getEnvironmentConstant({ key: ROUTER_BASENAME, env })}/list`
    ):
    case curPath.includes(
      `${getEnvironmentConstant({ key: ROUTER_BASENAME, env })}/dashboard`
    ): {
      window.location = '/support/technical';
      break;
    }
    default: {
      break;
    }
  }
};
