/* eslint-disable */
import { i18n } from '@lingui/core';
import { de, en, es, fr, it, ja, ko, pt, zh } from 'make-plural/plurals';
import logger from '../common/logger.js';

const pluralCodes = {
  'de-DE': de,
  'en-US': en,
  'es-ES': es,
  'fr-FR': fr,
  'it-IT': it,
  'ja-JP': ja,
  'ko-KR': ko,
  'pt-BR': pt,
  'zh-CN': zh
};

Object.keys(pluralCodes).forEach((locale) => {
  i18n.loadLocaleData(locale, { plurals: pluralCodes[locale] });
});

export const catalogs = {
  'de-DE': require('../../locales/de-DE/messages.js'),
  'en-US': require('../../locales/en-US/messages.js'),
  'es-ES': require('../../locales/es-ES/messages.js'),
  'fr-FR': require('../../locales/fr-FR/messages.js'),
  'it-IT': require('../../locales/it-IT/messages.js'),
  'ja-JP': require('../../locales/ja-JP/messages.js'),
  'ko-KR': require('../../locales/ko-KR/messages.js'),
  'pt-BR': require('../../locales/pt-BR/messages.js'),
  'zh-CN': require('../../locales/zh-CN/messages.js')
};

// consider changing defaultLanguageCountry to defaultLocale
export const defaultLanguageCountry = 'en-US';
export const defaultLanguageKey = 'en';

i18n.load(defaultLanguageCountry, {});
i18n.activate(defaultLanguageCountry);

/**
 * We do a dynamic import of just the catalog that we need
 *
 * @param locale any valid locale string
 */
export function activateLanguage(locale) {
  // Note: Dynamically Import Catlogs, This approach is not well tested to be considered in future
  if (catalogs[locale]) {
    i18n.load(locale, catalogs[locale].messages);
    i18n.activate(locale);
  } else {
    i18n.load(
      defaultLanguageCountry,
      catalogs[defaultLanguageCountry].messages
    );
    i18n.activate(defaultLanguageCountry);
  }
}

/**
 * Used for storybook to display the different languages we support.
 * We may want to update and use the AEM languages to use in storybook
 * (see below) for validation testing.
 */
export const languageOptionList = {
  'de-DE': 'de-DE',
  'en-US': 'en-US',
  'es-ES': 'es-ES',
  'fr-FR': 'fr-FR',
  'it-IT': 'it-IT',
  'ja-JP': 'ja-JP',
  'ko-KR': 'ko-KR',
  'pt-BR': 'pt-BR',
  'zh-CN': 'zh-CN'
};

/**
 * Useful for storybook to map the AEM language sites to
 * Support languages. languageOptionList are the actual languages
 * we translate into. Possibly combine with dotComDataByLang.
 * This is just easy to include for an option dropdown as is.
 */
export const aemMFELanguages = {
  'en-AE': 'en-US',
  'en-AU': 'en-US',
  'en-CA': 'en-US',
  'en-EG': 'en-US',
  'en-GB': 'en-US',
  'en-HK': 'en-US',
  'en-ID': 'en-US',
  'en-IE': 'en-US',
  'en-IL': 'en-US',
  'en-IN': 'en-US',
  'en-KW': 'en-US',
  'en-MY': 'en-US',
  'en-NL': 'en-US',
  'en-NZ': 'en-US',
  'en-OM': 'en-US',
  'en-PH': 'en-US',
  'en-QA': 'en-US',
  'en-SA': 'en-US',
  'en-SG': 'en-US',
  'en-TH': 'en-US',
  'en-US': 'en-US',
  'en-ZA': 'en-US',
  'cs-CZ': 'en-US',
  'da-DK': 'en-US',
  'fi-FI': 'en-US',
  'hu-HU': 'en-US',
  'nl-BE': 'en-US',
  'nl-NL': 'en-US',
  'no-NO': 'en-US',
  'pl-PL': 'en-US',
  'sv-SE': 'en-US',
  'tr-TR': 'en-US',
  'fr-BE': 'fr-FR',
  'fr-CA': 'fr-FR',
  'fr-CH': 'fr-FR',
  'fr-FR': 'fr-FR',
  'de-AT': 'de-DE',
  'de-CH': 'de-DE',
  'de-DE': 'de-DE',
  'it-CH': 'it-IT',
  'it-IT': 'it-IT',
  'es-AR': 'es-ES',
  'es-CL': 'es-ES',
  'es-CO': 'es-ES',
  'es-ES': 'es-ES',
  'es-MX': 'es-ES',
  'es-PE': 'es-ES',
  'es-UY': 'es-ES',
  'ja-JP': 'ja-JP',
  'ko-KR': 'ko-KR',
  'pt-BR': 'pt-BR',
  'pt-PT': 'pt-BR',
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-CN'
};

/**
 * Subdomains for use in non-product. Could be in an environment setup file
 * but we don't have anything else to store.
 *
 * These may go away once dotcom changes how they deploy domains - moving
 * away from www.autodesk.de to autodesk.com/de which probably includes
 * removing the subdomains as well.
 */
export const productionSubdomains = ['www', 'asean', 'latinoamerica'];
export const stagingSubdomains = ['www-pt', 'asean-pt', 'latinoamerica-pt'];

/**
 * Original path prefixes still needed for deprecated functionality.
 */
const oldSitePrefixes = ['en', 'fr', 'it', 'nl'];

/**
 * New list of endings for dotcom
 * No change for china (https://www.autodesk.com.cn)
 * No site language for English (www.autodesk.com)
 */
export const pathPrefixes = [
  'de',
  'en',
  'es',
  'fr',
  'it',
  'jp',
  'kr',
  'pt',
  'au',
  'be-nl',
  'be-fr',
  'br',
  'ca-en',
  'ca-fr',
  'cz',
  'dk',
  'eu',
  'hk',
  'in',
  'latam',
  'hu',
  'asean',
  'mx',
  'ae',
  'nl',
  'nz',
  'no',
  'pl',
  'sg',
  'fi',
  'se',
  'ch-de',
  'za',
  'ch-fr',
  'ch-it',
  'tr',
  'uk',
  'tw'
];
// https://www.autodesk.com
// https://www.autodesk.com/au
// https://www.autodesk.com/be-nl
// https://www.autodesk.com/be-fr
// https://www.autodesk.com/br
// https://www.autodesk.com/ca-en
// https://www.autodesk.com/ca-fr
// https://www.autodesk.com/cz
// https://www.autodesk.com/dk
// https://www.autodesk.com/de
// https://www.autodesk.com/es
// https://www.autodesk.com/eu
// https://www.autodesk.com/fr
// https://www.autodesk.com/hk
// https://www.autodesk.com/in
// https://www.autodesk.com/it
// https://www.autodesk.com/latam
// https://www.autodesk.com/hu
// https://www.autodesk.com/asean
// https://www.autodesk.com/mx
// https://www.autodesk.com/ae
// https://www.autodesk.com/nl
// https://www.autodesk.com/nz
// https://www.autodesk.com/no
// https://www.autodesk.com/pl
// https://www.autodesk.com/pt
// https://www.autodesk.com/sg
// https://www.autodesk.com/fi
// https://www.autodesk.com/se
// https://www.autodesk.com/ch-de
// https://www.autodesk.com/za
// https://www.autodesk.com/ch-fr
// https://www.autodesk.com/ch-it
// https://www.autodesk.com/tr
// https://www.autodesk.com/uk
// https://www.autodesk.com.cn
// https://www.autodesk.com/tw
// https://www.autodesk.com/jp
// https://www.autodesk.com/kr
/**
 * There are 54 language mappings in AEM (according to link alts on home page).
 * en-US is default. Most map back to English. Support is only in 9 languages, including English.
 * This mapping includes all 54 languages and the dotcom sites they reside on.
 * For the unsupported languages, the lang is set to 'en'.
 */
const prdDomain = 'www.autodesk';
const stgDomain = 'www-pt.autodesk';
const newPrdDomain = 'www.autodesk.com';
const newStgDomain = 'www-pt.autodesk.com';

/**
 * Added pathPrefix AND there is NONE set for en-US (not required) and zh-CN (not being consolidated
 * in the domain consolidation project)
 */
export const dotcomDataByLang = {
  'de-DE': {
    lang: 'de',
    pathPrefix: '/de',
    prd: {
      oldDomain: prdDomain + '.de',
      newDomain: newPrdDomain + '/de'
    },
    stg: {
      oldDomain: stgDomain + '.de',
      newDomain: newStgDomain + '/de'
    },
    dev: {
      oldDomain: stgDomain + '.de',
      newDomain: newStgDomain + '/de'
    }
  },
  'en-US': {
    lang: 'en',
    prd: {
      oldDomain: newPrdDomain,
      newDomain: newPrdDomain
    },
    stg: {
      oldDomain: newStgDomain,
      newDomain: newStgDomain
    },
    dev: {
      oldDomain: newStgDomain,
      newDomain: newStgDomain
    }
  },
  'es-ES': {
    lang: 'es',
    pathPrefix: '/es',
    prd: {
      oldDomain: prdDomain + '.es',
      newDomain: newPrdDomain + '/es'
    },
    stg: {
      oldDomain: stgDomain + '.es',
      newDomain: newStgDomain + '/es'
    },
    dev: {
      oldDomain: stgDomain + '.es',
      newDomain: newStgDomain + '/es'
    }
  },
  'fr-FR': {
    lang: 'fr',
    pathPrefix: '/fr',
    prd: {
      oldDomain: prdDomain + '.fr',
      newDomain: newPrdDomain + '/fr'
    },
    stg: {
      oldDomain: stgDomain + '.fr',
      newDomain: newStgDomain + '/fr'
    },
    dev: {
      oldDomain: stgDomain + '.fr',
      newDomain: newStgDomain + '/fr'
    }
  },
  'it-IT': {
    lang: 'it',
    pathPrefix: '/it',
    prd: {
      oldDomain: prdDomain + '.it',
      newDomain: newPrdDomain + '/it'
    },
    stg: {
      oldDomain: stgDomain + '.it',
      newDomain: newStgDomain + '/it'
    },
    dev: {
      oldDomain: stgDomain + '.it',
      newDomain: newStgDomain + '/it'
    }
  },
  'ja-JP': {
    lang: 'ja',
    pathPrefix: '/jp',
    prd: {
      oldDomain: prdDomain + '.co.jp',
      newDomain: newPrdDomain + '/jp'
    },
    stg: {
      oldDomain: stgDomain + '.co.jp',
      newDomain: newStgDomain + '/jp'
    },
    dev: {
      oldDomain: stgDomain + '.co.jp',
      newDomain: newStgDomain + '/jp'
    }
  },
  'ko-KR': {
    lang: 'ko',
    pathPrefix: '/kr',
    prd: {
      oldDomain: prdDomain + '.co.kr',
      newDomain: newPrdDomain + '/kr'
    },
    stg: {
      oldDomain: stgDomain + '.co.kr',
      newDomain: newStgDomain + '/kr'
    },
    dev: {
      oldDomain: stgDomain + '.co.kr',
      newDomain: newStgDomain + '/kr'
    }
  },
  'pt-BR': {
    lang: 'pt_br',
    pathPrefix: '/br',
    prd: {
      oldDomain: prdDomain + '.com.br',
      newDomain: newPrdDomain + '/br'
    },
    stg: {
      oldDomain: stgDomain + '.com.br',
      newDomain: newStgDomain + '/br'
    },
    dev: {
      oldDomain: stgDomain + '.com.br',
      newDomain: newStgDomain + '/br'
    }
  },
  'zh-CN': {
    lang: 'zh',
    prd: {
      oldDomain: prdDomain + '.com.cn',
      newDomain: prdDomain + '.com.cn'
    },
    stg: {
      oldDomain: stgDomain + '.com.cn',
      newDomain: stgDomain + '.com.cn'
    },
    dev: {
      oldDomain: stgDomain + '.com.cn',
      newDomain: stgDomain + '.com.cn'
    }
  },
  'cs-CZ': {
    lang: 'en',
    pathPrefix: '/cz',
    prd: {
      oldDomain: prdDomain + '.cz',
      newDomain: newPrdDomain + '/cz'
    },
    stg: {
      oldDomain: stgDomain + '.cz',
      newDomain: newStgDomain + '/cz'
    },
    dev: {
      oldDomain: stgDomain + '.cz',
      newDomain: newStgDomain + '/cz'
    }
  },
  'da-DK': {
    lang: 'en',
    pathPrefix: '/dk',
    prd: {
      oldDomain: prdDomain + '.dk',
      newDomain: newPrdDomain + '/dk'
    },
    stg: {
      oldDomain: stgDomain + '.dk',
      newDomain: newStgDomain + '/dk'
    },
    dev: {
      oldDomain: stgDomain + '.dk',
      newDomain: newStgDomain + '/dk'
    }
  },
  'de-CH': {
    lang: 'de',
    pathPrefix: '/ch-de',
    prd: {
      oldDomain: prdDomain + '.ch/de',
      newDomain: newPrdDomain + '/ch-de'
    },
    stg: {
      oldDomain: stgDomain + '.ch/de',
      newDomain: newStgDomain + '/ch-de'
    },
    dev: {
      oldDomain: stgDomain + '.ch/de',
      newDomain: newStgDomain + '/ch-de'
    }
  },
  'de-AT': {
    lang: 'de',
    pathPrefix: '/de',
    prd: {
      oldDomain: prdDomain + '.de',
      newDomain: newPrdDomain + '/de'
    },
    stg: {
      oldDomain: stgDomain + '.de',
      newDomain: newStgDomain + '/de'
    },
    dev: {
      oldDomain: stgDomain + '.de',
      newDomain: newStgDomain + '/de'
    }
  },
  'en-AU': {
    lang: 'en',
    pathPrefix: '/au',
    prd: {
      oldDomain: prdDomain + '.au',
      newDomain: newPrdDomain + '/au'
    },
    stg: {
      oldDomain: stgDomain + '.au',
      newDomain: newStgDomain + '/au'
    },
    dev: {
      oldDomain: stgDomain + '.au',
      newDomain: newStgDomain + '/au'
    }
  },
  'en-CA': {
    lang: 'en',
    pathPrefix: '/ca-en',
    prd: {
      oldDomain: prdDomain + '.ca/en',
      newDomain: newPrdDomain + '/ca-en'
    },
    stg: {
      oldDomain: stgDomain + '.ca/en',
      newDomain: newStgDomain + '/ca-en'
    },
    dev: {
      oldDomain: stgDomain + '.ca/en',
      newDomain: newStgDomain + '/ca-en'
    }
  },
  'en-GB': {
    lang: 'en',
    pathPrefix: '/uk',
    prd: {
      oldDomain: prdDomain + '.co.uk',
      newDomain: newPrdDomain + '/uk'
    },
    stg: {
      oldDomain: stgDomain + '.co.uk',
      newDomain: newStgDomain + '/uk'
    },
    dev: {
      oldDomain: stgDomain + '.co.uk',
      newDomain: newStgDomain + '/uk'
    }
  },
  'en-HK': {
    lang: 'en',
    pathPrefix: '/hk',
    prd: {
      oldDomain: prdDomain + '.com.hk',
      newDomain: newPrdDomain + '/hk'
    },
    stg: {
      oldDomain: stgDomain + '.com.hk',
      newDomain: newStgDomain + '/hk'
    },
    dev: {
      oldDomain: stgDomain + '.com.hk',
      newDomain: newStgDomain + '/hk'
    }
  },
  'en-IN': {
    lang: 'en',
    pathPrefix: '/in',
    prd: {
      oldDomain: prdDomain + '.in',
      newDomain: newPrdDomain + '/in'
    },
    stg: {
      oldDomain: stgDomain + '.in',
      newDomain: newStgDomain + '/in'
    },
    dev: {
      oldDomain: stgDomain + '.in',
      newDomain: newStgDomain + '/in'
    }
  },
  'en-IE': {
    lang: 'en',
    pathPrefix: '/eu',
    prd: {
      oldDomain: prdDomain + '.eu',
      newDomain: newPrdDomain + '/eu'
    },
    stg: {
      oldDomain: stgDomain + '.eu',
      newDomain: newStgDomain + '/eu'
    },
    dev: {
      oldDomain: stgDomain + '.eu',
      newDomain: newStgDomain + '/eu'
    }
  },
  'en-NL': {
    lang: 'en',
    pathPrefix: '/eu',
    prd: {
      oldDomain: prdDomain + '.eu',
      newDomain: newPrdDomain + '/eu'
    },
    stg: {
      oldDomain: stgDomain + '.eu',
      newDomain: newStgDomain + '/eu'
    },
    dev: {
      oldDomain: stgDomain + '.eu',
      newDomain: newStgDomain + '/eu'
    }
  },
  'en-TH': {
    lang: 'en',
    pathPrefix: '/asean',
    prd: {
      oldDomain: 'asean.autodesk.com',
      newDomain: newPrdDomain + '/asean'
    },
    stg: {
      oldDomain: 'asean-pt.autodesk.com',
      newDomain: newStgDomain + '/asean'
    },
    dev: {
      oldDomain: 'asean-pt.autodesk.com',
      newDomain: newStgDomain + '/asean'
    }
  },
  'en-ID': {
    lang: 'en',
    pathPrefix: '/asean',
    prd: {
      oldDomain: 'asean.autodesk.com',
      newDomain: newPrdDomain + '/asean'
    },
    stg: {
      oldDomain: 'asean-pt.autodesk.com',
      newDomain: newStgDomain + '/asean'
    },
    dev: {
      oldDomain: 'asean-pt.autodesk.com',
      newDomain: newStgDomain + '/asean'
    }
  },
  'en-PH': {
    lang: 'en',
    pathPrefix: '/asean',
    prd: {
      oldDomain: 'asean.autodesk.com',
      newDomain: newPrdDomain + '/asean'
    },
    stg: {
      oldDomain: 'asean-pt.autodesk.com',
      newDomain: newStgDomain + '/asean'
    },
    dev: {
      oldDomain: 'asean-pt.autodesk.com',
      newDomain: newStgDomain + '/asean'
    }
  },
  'en-MY': {
    lang: 'en',
    pathPrefix: '/asean',
    prd: {
      oldDomain: 'asean.autodesk.com',
      newDomain: newPrdDomain + '/asean'
    },
    stg: {
      oldDomain: 'asean-pt.autodesk.com',
      newDomain: newStgDomain + '/asean'
    },
    dev: {
      oldDomain: 'asean-pt.autodesk.com',
      newDomain: newStgDomain + '/asean'
    }
  },
  'en-NZ': {
    lang: 'en',
    pathPrefix: '/nz',
    prd: {
      oldDomain: prdDomain + '.co.nz',
      newDomain: newPrdDomain + '/nz'
    },
    stg: {
      oldDomain: stgDomain + '.co.nz',
      newDomain: newStgDomain + '/nz'
    },
    dev: {
      oldDomain: stgDomain + '.co.nz',
      newDomain: newStgDomain + '/nz'
    }
  },
  'en-SG': {
    lang: 'en',
    pathPrefix: '/sg',
    prd: {
      oldDomain: prdDomain + '.com.sg',
      newDomain: newPrdDomain + '/sg'
    },
    stg: {
      oldDomain: stgDomain + '.com.sg',
      newDomain: newStgDomain + '/sg'
    },
    dev: {
      oldDomain: stgDomain + '.com.sg',
      newDomain: newStgDomain + '/sg'
    }
  },
  'en-ZA': {
    lang: 'en',
    pathPrefix: '/za',
    prd: {
      oldDomain: prdDomain + '.co.za',
      newDomain: newPrdDomain + '/za'
    },
    stg: {
      oldDomain: stgDomain + '.co.za',
      newDomain: newStgDomain + '/za'
    },
    dev: {
      oldDomain: stgDomain + '.co.za',
      newDomain: newStgDomain + '/za'
    }
  },
  'en-AE': {
    lang: 'en',
    pathPrefix: '/ae',
    prd: {
      oldDomain: prdDomain + '.ae',
      newDomain: newPrdDomain + '/ae'
    },
    stg: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    },
    dev: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    }
  },
  'en-EG': {
    lang: 'en',
    pathPrefix: '/ae',
    prd: {
      oldDomain: prdDomain + '.ae',
      newDomain: newPrdDomain + '/ae'
    },
    stg: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    },
    dev: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    }
  },
  'en-IL': {
    lang: 'en',
    pathPrefix: '/ae',
    prd: {
      oldDomain: prdDomain + '.ae',
      newDomain: newPrdDomain + '/ae'
    },
    stg: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    },
    dev: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    }
  },
  'en-KW': {
    lang: 'en',
    pathPrefix: '/ae',
    prd: {
      oldDomain: prdDomain + '.ae',
      newDomain: newPrdDomain + '/ae'
    },
    stg: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    },
    dev: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    }
  },
  'en-OM': {
    lang: 'en',
    pathPrefix: '/ae',
    prd: {
      oldDomain: prdDomain + '.ae',
      newDomain: newPrdDomain + '/ae'
    },
    stg: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    },
    dev: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    }
  },
  'en-QA': {
    lang: 'en',
    pathPrefix: '/ae',
    prd: {
      oldDomain: prdDomain + '.ae',
      newDomain: newPrdDomain + '/ae'
    },
    stg: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    },
    dev: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    }
  },
  'en-SA': {
    lang: 'en',
    pathPrefix: '/ae',
    prd: {
      oldDomain: prdDomain + '.ae',
      newDomain: newPrdDomain + '/ae'
    },
    stg: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    },
    dev: {
      oldDomain: stgDomain + '.ae',
      newDomain: newStgDomain + '/ae'
    }
  },
  'es-AR': {
    lang: 'es',
    pathPrefix: '/latam',
    prd: {
      oldDomain: 'latinoamerica.autodesk.com',
      newDomain: newPrdDomain + '/latam'
    },
    stg: {
      oldDomain: 'latinoamerica-pt.autodesk.com',
      newDomain: newStgDomain + '/latam'
    },
    dev: {
      oldDomain: 'latinoamerica-pt.autodesk.com',
      newDomain: newStgDomain + '/latam'
    }
  },
  'es-PE': {
    lang: 'es',
    pathPrefix: '/latam',
    prd: {
      oldDomain: 'latinoamerica.autodesk.com',
      newDomain: newPrdDomain + '/latam'
    },
    stg: {
      oldDomain: 'latinoamerica-pt.autodesk.com',
      newDomain: newStgDomain + '/latam'
    },
    dev: {
      oldDomain: 'latinoamerica-pt.autodesk.com',
      newDomain: newStgDomain + '/latam'
    }
  },
  'es-CO': {
    lang: 'es',
    pathPrefix: '/latam',
    prd: {
      oldDomain: 'latinoamerica.autodesk.com',
      newDomain: newPrdDomain + '/latam'
    },
    stg: {
      oldDomain: 'latinoamerica-pt.autodesk.com',
      newDomain: newStgDomain + '/latam'
    },
    dev: {
      oldDomain: 'latinoamerica-pt.autodesk.com',
      newDomain: newStgDomain + '/latam'
    }
  },
  'es-CL': {
    lang: 'es',
    pathPrefix: '/latam',
    prd: {
      oldDomain: 'latinoamerica.autodesk.com',
      newDomain: newPrdDomain + '/latam'
    },
    stg: {
      oldDomain: 'latinoamerica-pt.autodesk.com',
      newDomain: newStgDomain + '/latam'
    },
    dev: {
      oldDomain: 'latinoamerica-pt.autodesk.com',
      newDomain: newStgDomain + '/latam'
    }
  },
  'es-UY': {
    lang: 'es',
    pathPrefix: '/latam',
    prd: {
      oldDomain: 'latinoamerica.autodesk.com',
      newDomain: newPrdDomain + '/latam'
    },
    stg: {
      oldDomain: 'latinoamerica-pt.autodesk.com',
      newDomain: newStgDomain + '/latam'
    },
    dev: {
      oldDomain: 'latinoamerica-pt.autodesk.com',
      newDomain: newStgDomain + '/latam'
    }
  },
  'es-MX': {
    lang: 'es',
    pathPrefix: '/mx',
    prd: {
      oldDomain: prdDomain + '.mx',
      newDomain: newPrdDomain + '/mx'
    },
    stg: {
      oldDomain: stgDomain + '.mx',
      newDomain: newStgDomain + '/mx'
    },
    dev: {
      oldDomain: stgDomain + '.mx',
      newDomain: newStgDomain + '/mx'
    }
  },
  'fi-FI': {
    lang: 'en',
    pathPrefix: '/fi',
    prd: {
      oldDomain: prdDomain + '.fi',
      newDomain: newPrdDomain + '/fi'
    },
    stg: {
      oldDomain: stgDomain + '.fi',
      newDomain: newStgDomain + '/fi'
    },
    dev: {
      oldDomain: stgDomain + '.fi',
      newDomain: newStgDomain + '/fi'
    }
  },
  'fr-BE': {
    lang: 'fr',
    pathPrefix: '/be-fr',
    prd: {
      oldDomain: prdDomain + '.be/fr',
      newDomain: newPrdDomain + '/be-fr'
    },
    stg: {
      oldDomain: stgDomain + '.be/fr',
      newDomain: newStgDomain + '/be-fr'
    },
    dev: {
      oldDomain: stgDomain + '.be/fr',
      newDomain: newStgDomain + '/be-fr'
    }
  },
  'fr-CA': {
    lang: 'fr',
    pathPrefix: '/ca-fr',
    prd: {
      oldDomain: prdDomain + '.ca/fr',
      newDomain: newPrdDomain + '/ca-fr'
    },
    stg: {
      oldDomain: stgDomain + '.ca/fr',
      newDomain: newStgDomain + '/ca-fr'
    },
    dev: {
      oldDomain: stgDomain + '.ca/fr',
      newDomain: newStgDomain + '/ca-fr'
    }
  },
  'fr-CH': {
    lang: 'fr',
    pathPrefix: '/ch-fr',
    prd: {
      oldDomain: prdDomain + '.ch/fr',
      newDomain: newPrdDomain + '/ch-fr'
    },
    stg: {
      oldDomain: stgDomain + '.ch/fr',
      newDomain: newStgDomain + '/ch-fr'
    },
    dev: {
      oldDomain: stgDomain + '.ch/fr',
      newDomain: newStgDomain + '/ch-fr'
    }
  },
  'hu-HU': {
    lang: 'en',
    pathPrefix: '/hu',
    prd: {
      oldDomain: prdDomain + '.hu',
      newDomain: newPrdDomain + '/hu'
    },
    stg: {
      oldDomain: stgDomain + '.hu',
      newDomain: newStgDomain + '/hu'
    },
    dev: {
      oldDomain: stgDomain + '.hu',
      newDomain: newStgDomain + '/hu'
    }
  },
  'it-CH': {
    lang: 'it',
    pathPrefix: '/ch-it',
    prd: {
      oldDomain: prdDomain + '.ch/it',
      newDomain: newPrdDomain + '/ch-it'
    },
    stg: {
      oldDomain: stgDomain + '.ch/it',
      newDomain: newStgDomain + '/ch-it'
    },
    dev: {
      oldDomain: stgDomain + '.ch/it',
      newDomain: newStgDomain + '/ch-it'
    }
  },
  'nl-BE': {
    lang: 'en',
    pathPrefix: '/be-nl',
    prd: {
      oldDomain: prdDomain + '.be/nl',
      newDomain: newPrdDomain + '/be-nl'
    },
    stg: {
      oldDomain: stgDomain + '.be/nl',
      newDomain: newStgDomain + '/be-nl'
    },
    dev: {
      oldDomain: stgDomain + '.be/nl',
      newDomain: newStgDomain + '/be-nl'
    }
  },
  'nl-NL': {
    lang: 'en',
    pathPrefix: '/nl',
    prd: {
      oldDomain: prdDomain + '.nl',
      newDomain: newPrdDomain + '/nl'
    },
    stg: {
      oldDomain: stgDomain + '.nl',
      newDomain: newStgDomain + '/nl'
    },
    dev: {
      oldDomain: stgDomain + '.nl',
      newDomain: newStgDomain + '/nl'
    }
  },
  'no-NO': {
    lang: 'en',
    pathPrefix: '/no',
    prd: {
      oldDomain: prdDomain + '.no',
      newDomain: newPrdDomain + '/no'
    },
    stg: {
      oldDomain: stgDomain + '.no',
      newDomain: newStgDomain + '/no'
    },
    dev: {
      oldDomain: stgDomain + '.no',
      newDomain: newStgDomain + '/no'
    }
  },
  'pl-PL': {
    lang: 'en',
    pathPrefix: '/pl',
    prd: {
      oldDomain: prdDomain + '.pl',
      newDomain: newPrdDomain + '/pl'
    },
    stg: {
      oldDomain: stgDomain + '.pl',
      newDomain: newStgDomain + '/pl'
    },
    dev: {
      oldDomain: stgDomain + '.pl',
      newDomain: newStgDomain + '/pl'
    }
  },
  'pt-PT': {
    lang: 'pt',
    pathPrefix: '/pt',
    prd: {
      oldDomain: prdDomain + '.pt',
      newDomain: newPrdDomain + '/pt'
    },
    stg: {
      oldDomain: stgDomain + '.pt',
      newDomain: newStgDomain + '/pt'
    },
    dev: {
      oldDomain: stgDomain + '.pt',
      newDomain: newStgDomain + '/pt'
    }
  },
  'sv-SE': {
    lang: 'en',
    pathPrefix: '/se',
    prd: {
      oldDomain: prdDomain + '.se',
      newDomain: newPrdDomain + '/se'
    },
    stg: {
      oldDomain: stgDomain + '.se',
      newDomain: newStgDomain + '/se'
    },
    dev: {
      oldDomain: stgDomain + '.se',
      newDomain: newStgDomain + '/se'
    }
  },
  'tr-TR': {
    lang: 'en',
    pathPrefix: '/tr',
    prd: {
      oldDomain: prdDomain + '.com.tr',
      newDomain: newPrdDomain + '/tr'
    },
    stg: {
      oldDomain: stgDomain + '.com.tr',
      newDomain: newStgDomain + '/tr'
    },
    dev: {
      oldDomain: stgDomain + '.com.tr',
      newDomain: newStgDomain + '/tr'
    }
  },
  'zh-TW': {
    lang: 'en',
    pathPrefix: '/tw',
    prd: {
      oldDomain: prdDomain + '.com.tw',
      newDomain: newPrdDomain + '/tw'
    },
    stg: {
      oldDomain: stgDomain + '.com.tw',
      newDomain: newStgDomain + '/tw'
    },
    dev: {
      oldDomain: stgDomain + '.com.tw',
      newDomain: newStgDomain + '/tw'
    }
  }
};

/**
 * Gets the default dotcom host - en-US for environment
 */
export const getDotcomDefaultHost = (env) => {
  const domainUrl = getDotcomDomainUrl(
    defaultLanguageCountry,
    dotcomDataByLang[defaultLanguageCountry][env]
  );
  return domainUrl;
};

/**
 * Gets the dotcom experience fragment. We have 2 for testing:
 * https://www-pt.autodesk.com/content/experience-fragments/autodesk/fr/support/technical/general-topics/master.html
 * https://www-pt.autodesk.com/content/experience-fragments/autodesk/fr/support/technical/contact-support/master.html
 *
 * @param {string} language
 * @param {string} env
 * @param {string} contentId
 * @returns {string} html of experience fragment
 */
export const getDotcomUrlForSupportTechnicalExperienceFragment = (
  language = defaultLanguageCountry,
  env = 'prd',
  contentId
) => {
  const lang = dotcomDataByLang[language]
    ? dotcomDataByLang[language]['lang']
    : dotcomDataByLang[defaultLanguageCountry]['lang'];
  const host = getDotcomDefaultHost(env);
  const url = `https://${host}/content/experience-fragments/autodesk/${lang}/support/technical/${contentId}/master.content.html`;
  return url;
};

/**
 * Gets the universal-footer experience fragment html by language and environment.
 * Content fragment uses lang as 'en', 'de', 'jp' and not the locale.
 *
 * @param {string} language
 * @param {string} env
 * @returns {string} html of experience fragment
 */
export const getDotcomUrlForFooter = (
  language = defaultLanguageCountry,
  env = 'prd'
) => {
  const lang = dotcomDataByLang[language]
    ? dotcomDataByLang[language]['lang']
    : dotcomDataByLang[defaultLanguageCountry]['lang'];

  // Use default domain as it appears that this content is only published
  // on primary dotcom domain, not other domains
  const host = getDotcomDefaultHost(env);
  const url = `https://${host}/content/experience-fragments/autodesk/${lang}/universal-footer/master.content.html`;
  return url;
};

/**
 * Gets the content fragment url by language and environment.
 * ex. all products
 * https://www-pt.autodesk.com/api/assets/autodesk/content-fragments/global/en/support/products.json
 *
 * ex. specific product
 * https://www-pt.autodesk.com/api/assets/autodesk/content-fragments/global/en/support/products/3ds-max.json
 *
 * ex. dictionary
 * https://www-pt.autodesk.com/api/assets/autodesk/content-fragments/global/en/support/dictionary.json
 *
 * @param {string} language
 * @param {string} env
 * @returns {string} url
 */
export const getDotcomUrlForContentFragment = (
  language = defaultLanguageCountry,
  env = 'prd',
  contentId
) => {
  const lang = dotcomDataByLang[language]
    ? dotcomDataByLang[language]['lang']
    : dotcomDataByLang[defaultLanguageCountry]['lang'];

  // do we need to validate contentId? If empty/undefined - fetch will throw an error later.
  // config ASSETS_API_PATH
  const ASSETS_API_PATH = 'api/assets';

  // Use default domain as it appears that this content is only published
  // on primary dotcom domain, not other domains
  const host = getDotcomDomainUrl(
    lang,
    dotcomDataByLang[defaultLanguageCountry][env]
  );

  let url = `${host}/${ASSETS_API_PATH}/autodesk/content-fragments/global/${lang}/support`;

  if (contentId !== 'dictionary' && contentId !== 'products') {
    url += '/products';
  }
  url += `/${encodeURIComponent(contentId)}.json`;

  return `https://${url}`;
};

/**
 * Gets the content fragment url by language and environment.
 * ex. all products
 * https://www-pt.autodesk.com/api/assets/autodesk/content-fragments/global/en/support/products.json
 *
 * ex. specific product
 * https://www-pt.autodesk.com/api/assets/autodesk/content-fragments/global/en/support/products/3ds-max.json
 *
 * ex. dictionary
 * https://www-pt.autodesk.com/api/assets/autodesk/content-fragments/global/en/support/dictionary.json
 *
 * @param {string} language
 * @param {string} env
 * @returns {string} url
 */
export const getDotcomUrlForContentExperienceFragment = (
  language = defaultLanguageCountry,
  env = 'prd',
  productID
) => {
  const lang = dotcomDataByLang[language]
    ? dotcomDataByLang[language]['lang']
    : dotcomDataByLang[defaultLanguageCountry]['lang'];
  const host = getDotcomDomainUrl(
    lang,
    dotcomDataByLang[defaultLanguageCountry][env]
  );
  const url = `https://${host}/content/experience-fragments/autodesk/${lang}/support/technical/products/${productID}/master.html`;
  return url;
};

/**
 * Returns the link relative html to other versions of the page being viewed.
 *
 * @param {string} env
 * @param {string} url
 * @returns {string} link rel html
 */
export const getLinksToOtherSites = (env = 'prd', url, queryString) => {
  let linkHtml = '';
  let queryParams = '';
  if (queryString) {
    queryParams = `?${queryString}`;
  }
  const defaultHost = getDotcomDomainUrl(
    defaultLanguageCountry,
    dotcomDataByLang[defaultLanguageCountry][env]
  );

  Object.keys(dotcomDataByLang).map((langKey) => {
    if (langKey.length && langKey.length > 2) {
      try {
        const lookupLangKey = aemMFELanguages[langKey];
        const host = getDotcomDomainUrl(
          lookupLangKey,
          dotcomDataByLang[lookupLangKey][env]
        );

        linkHtml += createAltRefLink(
          langKey.toLowerCase(),
          'https://' + host + url + queryParams
        );
      } catch (error) {
        // some misconfiguration happened...
        logger.error(`getLinksToOtherSites failed for ${langKey}`);
        // set default so we have someplace to send the users
        linkHtml += createAltRefLink(
          langKey.toLowerCase(),
          'https://' + defaultHost + url + queryParams
        );
      }
    }
  });

  // add the default to the list
  linkHtml += createAltRefLink(
    'x-default',
    'https://' + defaultHost + url + queryParams
  );

  // add static Russian link
  linkHtml +=
    '<meta name="alternate" data-locale="ru-ru" data-link="https://www.autodesk.com/ru-ru">';

  return linkHtml;
};

/**
 * Returns the locale url mapping object for Universal Header.
 * See languageSelector here: https://wiki.autodesk.com/display/EFDE/Universal+Header+-+Integration
 *
 * @param {string} env
 * @param {string} url
 * @returns {object} locale url mapping
 */
export const getLocaleUrlMapping = (env = 'prd', url, queryString) => {
  let localeUrls = [];
  let queryParams = '';
  if (queryString) {
    queryParams = `?${queryString}`;
  }

  const defaultHost = getDotcomDomainUrl(
    defaultLanguageCountry,
    dotcomDataByLang[defaultLanguageCountry][env]
  );

  Object.keys(dotcomDataByLang).map((langKey) => {
    if (langKey.length && langKey.length > 2) {
      try {
        const lookupLangKey = aemMFELanguages[langKey];
        const host = getDotcomDomainUrl(
          lookupLangKey,
          dotcomDataByLang[lookupLangKey][env]
        );
        const localeUrl = {
          locale: langKey,
          url: 'https://' + host + url + queryParams
        };
        localeUrls.push(localeUrl);
      } catch (error) {
        // some misconfiguration happened...
        logger.error(`getLocaleUrlMapping failed for ${langKey}`);
        // set default so we have someplace to send the users
        const localeUrl = {
          locale: langKey,
          url: 'https://' + defaultHost + url + queryParams
        };
        localeUrls.push(localeUrl);
      }
    }
  });
  return localeUrls;
};

/**
 * Returns the localized dotcom website url
 *
 * @param {string} language
 * @param {string} env
 * @returns {string} url to top level local dotcome website
 */
export const getLocalizedDotcomSite = (language, env = 'prd') => {
  let lang = !language ? defaultLanguageCountry : language;
  let host = '';
  // breaks if 'pt-br' and not 'pt-BR' - maybe make this smarter?!
  if (dotcomDataByLang[lang] && dotcomDataByLang[lang][env]) {
    host = getDotcomDomainUrl(lang, dotcomDataByLang[lang][env]);
  } else {
    host = getDotcomDomainUrl(
      defaultLanguageCountry,
      dotcomDataByLang[defaultLanguageCountry][env]
    );
  }
  return `https://${host}`;
};

/**
 * Gets the error page for the specific dotcom site. Ideally this returns the 404
 * in the language the user requests. This will be dependent on the actual site this
 * SSR app is running. So it won't always be in English.
 *
 * @param {string} language
 * @param {string} env
 * @returns {string} html of 404 page
 */
export const getLocalizedDotcomErrorPage = (language, env) => {
  return getLocalizedDotcomSite(language, env) + '/404';
};

/**
 * Creates the link rel html for a given language and url.
 *
 * @param {string} lang
 * @param {string} url
 * @returns
 */
export const createAltRefLink = (lang, url) => {
  return `<link rel="alternate" hreflang="${lang}" href="${url}"/>`;
};

/**
 * We want to only display content for the support languages that exist
 * in languageOptionList. We have a mapping from locale to our 9 languages.
 *
 * @param {string} lang
 * @returns boolean
 */
export const isValidSupportLanguage = (lang) => {
  if (languageOptionList[lang]) {
    return true;
  }
  return false;
};

/**
 * Lookup the locale/language from our dotcom hosts. Akamai will pass in host headers
 * const headerUrl = ctx.get('x-etx-support-url');
 * const headerHost = ctx.get('x-etx-support-host');
 *
 * This lookup only happens when these are present.
 *
 * Hosts can be:
 *
 * www.autodesk.com
 *
 * www.autodesk.de
 * www.autodesk.com/de
 * www.autodesk.ch/de
 * www.autodesk.com/ch-de
 *
 * To support backwards (www.autodesk.de) and forwards (www.autodesk.com/de)
 *
 * @param {string} host (can be empty)
 * @param {string} url (required)
 * @returns language code for application
 */
export const getLanguageFromUrl = (host, url) => {
  if (!url) {
    return '';
  }
  const urlSplit = host.split('.');
  let sub = '';
  let env = 'prd';
  if (urlSplit && urlSplit.length > 0) {
    sub = urlSplit[0];
  }
  if (stagingSubdomains.includes(sub)) {
    env = 'stg';
  }
  let language = '';

  const dotcomDataKeys = Object.keys(dotcomDataByLang);
  const domains = process.env.REACT_APP_UPDATED_DOMAINS
    ? process.env.REACT_APP_UPDATED_DOMAINS.split(',')
    : '';

  /**
   * Test for domains that may be updated for domain consolidation. It could be "all" or
   * a set of locales. We cannot test en-US because with domain consolidation, it will
   * match everything!
   */
  if (domains) {
    dotcomDataKeys.some(function (locale) {
      if (domains.includes('all') || domains.includes(locale)) {
        let dotcomDomainPath = dotcomDataByLang[locale]['pathPrefix']
          ? dotcomDataByLang[locale]['pathPrefix']
          : '';
        if (dotcomDomainPath && locale !== 'en-US') {
          dotcomDomainPath += '/';
          if (url.startsWith(dotcomDomainPath)) {
            language = locale;
            return true;
          }
        }
      }
    });
  }

  /**
   * We can no longer return en-US as a default for any pathPrefix and we want
   * to have this function return an empty string if there is no match.
   *
   * We have to be careful with this because the domain www.autodesk.com will match a
   * pageRequested www.autodesk.com/ae, etc. so I put in an extra check to make sure
   * we can return an empty string for pathPrefix that is not enabled yet.
   * Once a pathPrefix is enabled, the code above should return the proper language.
   */
  if (!language) {
    // check that URL does not have any prefix
    const pathParts = url.split('/').filter(function (x) {
      return x != '';
    });
    if (pathParts && Object.keys(pathParts).length > 1) {
      // we need to be able to redirect old site prefixes .be/nl, .be/fr, .ch/de, .ch/fr, .ch/it
      const pageRequested = `${host}${url}`;
      dotcomDataKeys.some(function (locale) {
        // gets the domain dynamically
        let dotcomDataObj = getDotcomDomainUrl(
          locale,
          dotcomDataByLang[locale][env]
        );
        if (pageRequested.indexOf(dotcomDataObj + '/') != -1) {
          if (locale !== 'en-US' && oldSitePrefixes.includes(pathParts[0])) {
            language = locale;
            return true;
          }
        }

        // try again with the old domain - this is original code
        dotcomDataObj = dotcomDataByLang[locale][env]['oldDomain'];
        if (pageRequested.indexOf(dotcomDataObj + '/') != -1) {
          if (locale !== 'en-US') {
            language = locale;
            return true;
          }
        }
      });
    }
  }

  return language;
};

/**
 * Get a URL to redirect a request to a supported language site.
 * We have 9 sites and 54 locales. So let's try and get the user
 * to the right place for support without rendering a 404 if we don't
 * have to.
 *
 * @param {string} lang
 * @param {string} env
 * @param {string} url
 * @return string URL of supported language site
 */
export const getSupportLanguageUrl = (lang, env, url) => {
  if (!url.startsWith('/support/technical')) {
    url = url.substring(url.indexOf('/support'), url.length);
  }
  let redirectUrl;
  try {
    let supportLang = aemMFELanguages[lang];
    const dotcomDomainUrl = getDotcomDomainUrl(
      supportLang,
      dotcomDataByLang[supportLang][env]
    );
    redirectUrl = 'https://' + dotcomDomainUrl + url;
  } catch (error) {
    if (!env) env = 'prd';
    redirectUrl =
      'https://' +
      getDotcomDomainUrl(
        defaultLanguageCountry,
        dotcomDataByLang[defaultLanguageCountry][env]
      ) +
      url;
  }
  return redirectUrl;
};

/**
 * These could possibly go into the Beehive Connector Util.
 * The translation of the types is inconsistent with what is in the Ontology manager so we
 * need a hard-coded, guaranteed to return data list for:
 *
 * Hotfixes
 * Object Enablers
 * Offline Help
 * Templates
 * Utilities
 */
export const types = {
  'de-DE': {
    hotfixes: 'Hotfixes',
    objectEnablers: 'Object Enablers',
    offlineHelp: 'Offline-Hilfe',
    templates: 'Vorlagen',
    utilities: 'Hilfsprogramme'
  },
  'en-US': {
    hotfixes: 'Hotfixes',
    objectEnablers: 'Object Enablers',
    offlineHelp: 'Offline Help',
    templates: 'Templates',
    utilities: 'Utilities'
  },
  'es-ES': {
    hotfixes: 'Revisiones',
    objectEnablers: 'Object Enablers',
    offlineHelp: 'Ayuda sin conexión',
    templates: 'Plantillas',
    utilities: 'Utilidades'
  },
  'fr-FR': {
    hotfixes: 'Correctifs',
    objectEnablers: "Programmes d'activation d'objet",
    offlineHelp: 'Aide hors ligne',
    templates: 'Gabarits',
    utilities: 'Utilitaires'
  },
  'it-IT': {
    hotfixes: 'Hotfix',
    objectEnablers: 'Ativador de objeto',
    offlineHelp: 'Guida non in linea',
    templates: 'Modelli',
    utilities: 'Utilità'
  },
  'ja-JP': {
    hotfixes: 'Hot Fix',
    objectEnablers: 'Object Enabler',
    offlineHelp: 'オフライン ヘルプ',
    templates: 'テンプレート',
    utilities: 'ユーティリティ'
  },
  'ko-KR': {
    hotfixes: '핫픽스',
    objectEnablers: 'Object Enabler',
    offlineHelp: '오프라인 도움말',
    templates: '템플릿',
    utilities: '공공 시설'
  },
  'pt-BR': {
    hotfixes: 'Hotfixes',
    objectEnablers: 'Object Enabler',
    offlineHelp: 'Ajuda off-line',
    templates: 'Modelos',
    utilities: 'Utilitários'
  },
  'zh-CN': {
    hotfixes: 'Hotfix',
    objectEnablers: 'Object Enabler',
    offlineHelp: '脱机帮助',
    templates: '模板',
    utilities: '实用程序'
  }
};

/**
 * Gets the type translation
 *
 * @param {string} language - only supported languages; no default because it won't work
 * @param {string} type - one of 5 strings (see types)
 * @returns translated Type
 */
export const getTypeTranslation = (language, type) => {
  if (!language || !type || !Object.hasOwn(types, language)) {
    return '';
  }
  return types[language][type];
};

/**
 * Helper function to get the dotcom domain.
 * We are working on domain consolidation and we have "old" and "new" domains.
 * REACT_APP_UPDATED_DOMAINS is a new variable and will be passed in comma delimited.
 * Rules for validation:
 *
 * 1. Missing or empty: oldDomain
 * 2. Has data, no match: oldDomain
 * 3. Has data and match found: newDomain (for pilot)
 * 4. Has "all": newDomain (for going live and using only "newDomain")
 */
export const getDotcomDomainUrl = (locale, dotcomDataObj) => {
  // if we get a bad object, return null?
  if (!dotcomDataObj || !dotcomDataObj.oldDomain || !dotcomDataObj.newDomain) {
    return null;
  }

  const domains = process.env.REACT_APP_UPDATED_DOMAINS
    ? process.env.REACT_APP_UPDATED_DOMAINS.split(',')
    : '';

  let dotcomDomainUrl = dotcomDataObj.oldDomain;

  if (domains.includes('all') || domains.includes(locale)) {
    dotcomDomainUrl = dotcomDataObj.newDomain;
  }

  return dotcomDomainUrl;
};
