import loadable from '@loadable/component';

import {
  COMPONENT_INDEX,
  COMPONENT_CAAS,
  COMPONENT_PRODUCT,
  COMPONENT_SEARCH,
  MAINTENANCE_MODE
} from './constants';

const importMap = {};

/* Enable Async module loading and code splitting for client */
importMap[COMPONENT_INDEX] = loadable(() =>
  import(/* webpackChunkName: "index" */ '../pageTypes/index')
);
importMap[COMPONENT_CAAS] = loadable(() =>
  import(/* webpackChunkName: "caas" */ '../pageTypes/caas')
);
importMap[COMPONENT_PRODUCT] = loadable(() =>
  import(/* webpackChunkName: "product" */ '../pageTypes/product')
);
importMap[COMPONENT_SEARCH] = loadable(() =>
  import(/* webpackChunkName: "search" */ '../pageTypes/search')
);
importMap[MAINTENANCE_MODE] = loadable(() =>
  import(
    /* webpackChunkName: "maintenanceMode" */ '../pageTypes/maintenanceMode'
  )
);

export default importMap;
