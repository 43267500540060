import { createAsyncThunk } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import authLib from '../common/pkce';
import logger from '../common/logger';

const fetchUserData = createAsyncThunk('userData', async () => {
  const auth = authLib.authClient;
  try {
    const { access_token: accessToken, id_token: idToken } =
      await auth.getAccessTokenSilently({
        ignoreCache: true,
        detailedResponse: true
      });
    const decodedIdToken = jwtDecode(idToken);

    const mappedData = {
      userid: decodedIdToken.userid
    };

    // Update login state for UH
    if (typeof accessToken !== 'undefined' && accessToken.length > 0) {
      const event = new CustomEvent('SSO_SIGNED_IN', {
        detail: { at: accessToken }
      });
      logger.debug('reRenderMeMenu CustomEvent', event);
      window.dispatchEvent(event);
    }

    return Promise.resolve(mappedData);
  } catch (e) {
    const event = new CustomEvent('SSO_SIGNED_OUT');
    window.dispatchEvent(event);
    if (!e.toString().includes('Login required')) {
      logger.error('PKCE ERROR w/ getTokenSilently:', e);
    }
    return Promise.reject(new Error('No PKCE Login'));
  }
});

export default fetchUserData;
