/* eslint-disable no-console */
import window from 'global';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import {
  LOG_LEVEL_DEBUG,
  LOG_LEVEL_INFO,
  LOG_LEVEL_WARN,
  LOG_LEVEL_ERROR,
  LOG_LEVEL,
  ENVIRONMENT_CONSTANTS,
  ENVIRONMENT,
  DEV_ENV,
  PRELOADED_STATE_KEY,
  LOGGER_URL,
  RETRY_COUNT,
  VERSION,
  ROUTER_BASENAME
} from './constants';

axiosRetry(axios, { retries: RETRY_COUNT });

const env =
  process.env[ENVIRONMENT] ||
  window[PRELOADED_STATE_KEY]?.envData[ENVIRONMENT] ||
  DEV_ENV;
const applicationLogLevel =
  process.env[LOG_LEVEL] ||
  window[PRELOADED_STATE_KEY]?.envData[LOG_LEVEL] ||
  ENVIRONMENT_CONSTANTS[env][LOG_LEVEL];

// Values used to determine if the request meets the current logging level requirement
const levelValues = {};
levelValues[LOG_LEVEL_DEBUG] = 0;
levelValues[LOG_LEVEL_INFO] = 1;
levelValues[LOG_LEVEL_WARN] = 2;
levelValues[LOG_LEVEL_ERROR] = 3;

/**
 * Process an argument passed to the logger and return message and stack trace if available.
 * Designed to handle the passing of strings, objects, and errors to the logger.
 *
 * @param arg
 * @returns {{message: *, stack: *}}
 */
function processArgument(arg) {
  let message;
  let stack;
  let preformatted;

  if (typeof arg === 'object') {
    if (arg.message) {
      if (arg.stack) {
        stack = arg.stack.replace(/\n/g, ';');
      }
      message = arg.message;
    } else if (arg.preformatted) {
      preformatted = arg.preformatted;
    } else {
      message = JSON.stringify(arg);
    }
  } else {
    message = arg;
  }

  return {
    message,
    stack,
    preformatted
  };
}

const formatMessage = (method, args) => {
  let stackTrace;
  let preformattedMsg;
  const errorMessage = [];

  // Get message and stack trace
  args.forEach((arg) => {
    const { message, stack, preformatted } = processArgument(arg);
    if (message) {
      errorMessage.push(message);
    }
    if (stack) {
      stackTrace = stack;
    }
    if (preformatted) {
      preformattedMsg = preformatted;
    }
  });

  if (preformattedMsg) {
    return preformattedMsg;
  }

  if (stackTrace) {
    errorMessage.push(stackTrace);
  }

  const logObj = {
    payload: errorMessage.join(' - '),
    timestamp: Date.now(),
    level: method,
    transactionId: 'Missing Transaction-ID',
    service: `ETX SPA ${window.location ? 'UI' : 'Server'}`,
    environment: env,
    'X-EtxVersion': window[PRELOADED_STATE_KEY]?.envData[VERSION]
  };

  // Add Browser Data
  if (window.location) {
    logObj.OS = window.navigator?.platform;
    logObj.Language = window.navigator?.language;
    logObj.Browser = window.navigator?.userAgent;
  }

  return logObj; // JSON.stringify(logObj);
};

/**
 * This method actually logs.
 *
 * @param method - debug, info, warn or error
 * @param args - strings/objects combined to generate the message and stack trace
 */
const log = (method, ...args) => {
  if (!Object.hasOwn(levelValues, method)) {
    throw new Error(
      'First parameter for the logger method must be debug/info/warn/error'
    );
  }

  if (levelValues[method] >= levelValues[applicationLogLevel]) {
    const options = {};
    if (window[PRELOADED_STATE_KEY]?.envData[VERSION]) {
      options.headers = {
        'X-EtxVersion': window[PRELOADED_STATE_KEY]?.envData[VERSION]
      };
    }

    console[method](method, formatMessage(method, args));

    const logCollectorUrl =
      window[PRELOADED_STATE_KEY]?.envData[LOGGER_URL] || false;
    if (window.location && logCollectorUrl) {
      // Handle contextual logging
      axios.post(
        logCollectorUrl.replace(
          '/support/technical',
          window[PRELOADED_STATE_KEY]?.envData[ROUTER_BASENAME]
        ),
        { msg: formatMessage(method, args) },
        options
      );
    }
  }
};

const trimString = (str, size) => {
  let newStr = str;
  if (typeof str !== 'string') {
    newStr = str.toString();
  }
  if (newStr && newStr.length > 0) {
    newStr = newStr.trim();
    if (size && newStr.length > size) {
      newStr = newStr.slice(0, size);
    }
  }
  return newStr;
};

/**
 * Validate the incoming data that can be logged via API.
 * Do not log everything received - payload could have more data than expected.
 *
 * payload - size is 2000 - monitor to see if we need it to be larger.
 * service - ETX SPA UI - hard-coding right now; validate it's this string; maybe we'll have others
 * environment - use server environment
 * OS - validate length, not content (100)
 * language - validate length, not content (10)
 * browser - validate length, not content (500)
 * X-EtxVersion - validate length, not content (1)
 * timestamp - validate length, not content (13)
 *
 * add transactionId ?? what data do we have that is unique?
 *
 * @param {object} msg
 */
const getPreformattedLogMessage = (msg = {}) => {
  const logMessage = {};
  logMessage.payload = msg?.payload
    ? trimString(msg.payload, 2000)
    : 'Unknown payload';
  logMessage.service = 'ETX SPA UI';
  logMessage.environment = env;
  logMessage.OS = msg?.OS ? trimString(msg.OS, 100) : 'Unknown OS';
  logMessage.Language = msg?.Language
    ? trimString(msg.Language, 10)
    : 'Unknown Language';
  logMessage.Browser = msg?.Browser
    ? trimString(msg.Browser, 500)
    : 'Unknown Browser';
  logMessage['X-EtxVersion'] = msg['X-EtxVersion']
    ? trimString(msg['X-EtxVersion'], 10)
    : 'Unknown Etx Version';
  logMessage.timestamp = msg?.timestamp
    ? trimString(msg.timestamp, 13)
    : Date.now();
  return logMessage;
};

const error = (...args) => log('error', ...args);
const warn = (...args) => log('warn', ...args);
const debug = (...args) => log('debug', ...args);
const info = (...args) => log('info', ...args);

export default {
  log,
  debug,
  info,
  warn,
  error,
  getPreformattedLogMessage
};
