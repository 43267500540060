import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  productLoading: undefined,
  productDocumentation: {},
  bannerBoxData: {},
  recommendationLoading: false,
  recommendedContent: [],
  customContent: undefined,
  customContentHtml: undefined,
  customContentLoading: false,
  productListCode: undefined,
  productName: undefined,
  error: { hasError: undefined, errorType: null, errorMetaData: null },
  productSlug: '',
  browseTypes: [],
  generalTopics: '',
  contactSupport: ''
};

const productData = createSlice({
  name: 'productDataApi',
  initialState,
  reducers: {}
});

export default productData.reducer;
