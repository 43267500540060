import window from 'global/window';
import { LOCAL_STORAGE_KEY, OXYGEN_REDIRECT_URL_KEY } from './constants';
import logger from './logger';
import authLib from './pkce';

export const setLoginRedirects = (redirectUrl) => {
  // Set current URL in session storage to be redirected to after login.
  window.sessionStorage.setItem(
    OXYGEN_REDIRECT_URL_KEY,
    redirectUrl
      ? JSON.stringify(redirectUrl)
      : JSON.stringify(document.location.href)
  );
  try {
    // Set current URL in local storage to be redirected to after verify of new acct.
    const lrsObject =
      JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
    lrsObject.redirUrl = redirectUrl || document.location.href;
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(lrsObject));
  } catch (e) {
    logger.debug('Failed setting LOCAL_STORAGE_KEY:  ', e);
  }
};

export const LoginClickHandler = (event, redirectUrl) => {
  setLoginRedirects(redirectUrl);
  // Redirect to Login
  authLib.authClient.loginWithRedirect();
  // window.DHXOxygen.showSignIn(OXYGEN_RESPONSE_URL);
  // });
};
