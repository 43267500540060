import window from 'global/window';
import {
  AWS_ENV_VARS,
  ENVIRONMENT_CONSTANTS,
  PRELOADED_STATE_KEY
} from './constants';
import logger from './logger';

export const stripTrailingSlash = (str) =>
  str.endsWith('/') ? str.slice(0, -1) : str;

export const getEnvironmentConstant = ({ key, env }) => {
  if (ENVIRONMENT_CONSTANTS[env] && ENVIRONMENT_CONSTANTS[env][key]) {
    const defaultValue = ENVIRONMENT_CONSTANTS[env][key];
    if (typeof window[PRELOADED_STATE_KEY]?.envData[key] !== 'undefined') {
      return window[PRELOADED_STATE_KEY]?.envData[key];
    }
    if (typeof process.env[key] !== 'undefined') {
      // Add Validation
      if (AWS_ENV_VARS[key]) {
        const processEnv = `${process.env[key]}`;
        if (AWS_ENV_VARS[key].default || processEnv) {
          // If not set and no default available hide the key
          if (typeof AWS_ENV_VARS[key].validator === 'function') {
            return AWS_ENV_VARS[key].validator(processEnv)
              ? processEnv
              : AWS_ENV_VARS[key].default;
          }
          return processEnv || AWS_ENV_VARS[key].default;
        }
      }
      return `${process.env[key]}`;
    }
    return defaultValue;
  }
  logger.error(
    `${key} not found in environment constants config for env ${env}`
  );
  return null;
};
